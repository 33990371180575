<template>
  <el-table :data="tableData" style="width: 100%">
    <el-table-column label="标题" width="150">
      <template slot-scope="scope">
        <span style="margin-left: 10px">{{ scope.row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column label="状态" width="120">
      <template slot-scope="scope">
        <span style="margin-left: 10px">{{ scope.row.sstate }}</span>
      </template>
    </el-table-column>
    <el-table-column label="下载量" width="100">
      <template slot-scope="scope">
        <span style="margin-left: 10px">{{ scope.row.download }}</span>
      </template>
    </el-table-column>
    <el-table-column label="评分" width="100">
      <template slot-scope="scope">
        <span style="margin-left: 10px">{{ scope.row.score }}</span>
      </template>
    </el-table-column>
    <el-table-column label="价格" width="100">
      <template slot-scope="scope">
        <span style="margin-left: 10px">{{ scope.row.Price }}</span>
      </template>
    </el-table-column>
    <el-table-column label="上传日期" width="120">
      <template slot-scope="scope">
        <span style="margin-left: 10px">{{ YearMMdd(scope.row.Time) }}</span>
      </template>
    </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button
          size="mini"
          type="danger"
          @click="handleDelete(scope.$index, scope.row)"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { INSERT, QUERY, DELETE } from "@/services/dao.js";
import { getTimeFormat } from "@/js/common";
export default {
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let res = await QUERY(
        "post",
        "",
        'ZYDocument_ALL( where: {user: {_eq: "' +
          this.$store.state.userinfo.id +
          '"}},limit: ' +
          10 +
          ", offset: " +
          0 +
          ") { id state  name  read   download score  Price Time}"
      );
      console.log(res.data.ZYDocument_ALL,'aaaaa');
      for (let i = 0; i < res.data.ZYDocument_ALL.length; i++) {
        // res.data.ZYDocument_ALL[i].Time = getTimeFormat(
        //   Date.parse(res.data.ZYDocument_ALL[i].Time)
        // );
        switch (res.data.ZYDocument_ALL[i].state) {
          case "1":
            res.data.ZYDocument_ALL[i].sstate = "待审核";
            break;
          case "2":
            res.data.ZYDocument_ALL[i].sstate = "审核通过 ";
            break;
          case "3":
            res.data.ZYDocument_ALL[i].sstate = "未通过审核";
            break;
        }
      }
      this.tableData = res.data.ZYDocument_ALL;
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    async handleDelete(index, row) {
      console.log(index, row);
      let info = await DELETE(
        "post",
        "",
        "delete_ZYDocument_all(where: {id: {_eq: " +
          row.id +
          "}}) {     affected_rows   }"
      );
      console.log(info.data.delete_ZYDocument_all.affected_rows);
      if (info.data.delete_ZYDocument_all.affected_rows > 0) {
        this.init();
        this.$message({
          message: "删除成功",
          type: "warning",
        });
      }
    },

    YearMMdd(value){ 
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d ;
    }
  },
};
</script>
<style scoped>
.el-table .cell {
  white-space: nowrap !important;
}
</style>
